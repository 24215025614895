import { environment } from "../../../../environments/environment";
import { Http, Headers } from "@angular/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";

export class DicomFiltro {
  patient_name;
  begin_date
  end_date
  exam_type
  unit
  patient_id
}

@Injectable()
export class DicomService {

  constructor(
    private http: Http,
    public auth: AuthService
  ) { }

  pesquisar(filtro: DicomFiltro): Promise<any> {
    const headers = new Headers();
    headers.append("Accept", "application/json");

    var objetoJson = {
      "patient_name": filtro.patient_name,
      "begin_date": filtro.begin_date,
      "end_date": filtro.end_date,
      "exam_type": filtro.exam_type,
      "unit": filtro.unit,
      "patient_id": filtro.patient_id
    };

    return this.http.post(`${environment.dicom}`, objetoJson, { headers })
      .toPromise()
      .then(response => response.json())
  }

  pesquisarLink(id): Promise<any> {
    const headers = new Headers();
    headers.append("Accept", "application/json");

    var objetoJson = {
      "apollo_uid": id
    };

    return this.http.post(`${environment.dicom}/link`, objetoJson, { headers })
      .toPromise()
      .then(response => response.json())
  }

}
