import { environment } from "../../../../environments/environment";
import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";
import { Fornecedor } from "../../models/estoque";



export class FornecedorFiltro {
    nome;
    cpfCnpj;
    pagina = 0;
    itensPorPagina = 20;
}

@Injectable()
export class FornecedorService {
    url: string;

    constructor(private http: AuthHttp, public auth: AuthService) {
        this.url = `${environment.apiUrl}/fornecedor`;
    }

    pesquisar(filtro: FornecedorFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set("page", filtro.pagina.toString());
        params.set("size", filtro.itensPorPagina.toString());

        if (filtro.nome) {
            params.set("nome", filtro.nome);
        }
        if (filtro.cpfCnpj) {
            params.set("cpfCnpj", filtro.cpfCnpj);
        }

        return this.http
            .get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => {
                const responseJson = response.json();
                return responseJson;
            });
    }

    listarTodos(): Promise<any> {
        const params = new URLSearchParams();
        return this.http
            .get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => response.json().content);
    }

    excluir(codigo: number): Promise<void> {
        return this.http
            .delete(`${this.url}/${codigo}`)
            .toPromise()
            .then(() => null);
    }

    adicionar(entidade: Fornecedor): Promise<Fornecedor> {
        return this.http
            .post(this.url, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    atualizar(entidade: Fornecedor): Promise<Fornecedor> {
        return this.http
            .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json() as Fornecedor);
    }

    buscarPorCodigo(codigo: number): Promise<Fornecedor> {
        return this.http
            .get(`${this.url}/${codigo}`)
            .toPromise()
            .then(response => {
                const retorno = response.json() as Fornecedor;
                return retorno;
            });
    }
}
